<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('menu.aerolinea')}` }}</v-card-title>
        <v-card-text><AerolineList></AerolineList></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import AerolineList from './form/AerolineList.vue'

export default {
  components: {
    AerolineList,
  },
}
</script>
